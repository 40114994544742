export class DegradeError extends Error {
	public isDegraded: boolean = true;
}

export const DEGRADE_TIMEOUT_MS = 10000;
const DEGRADE_ERROR = new DegradeError('Promise is degraded');

interface DegradablePromiseInterface<T> {
	/**
	 * Retrieves the `DegradablePromise` as a `Promise` so handlers like `then` and `catch` can then be attached.
	 */
	promise: () => Promise<T>;
}

type DegradationCallback = () => void;

export class DegradablePromise<T> implements DegradablePromiseInterface<T> {
	private degradablePromiseInternal: Promise<T>;
	private degradeTimeout: ReturnType<typeof setTimeout>;

	private degradeInternal: () => void = () => {
		// Default throws an error as this should never happen
		throw new Error('Programming error, cancelling promise is not properly rejected');
	};

	constructor(wrapped: Promise<T>, degradationCallback: DegradationCallback) {
		const degrader = new Promise<T>((_, reject) => {
			this.degradeInternal = () => {
				reject(DEGRADE_ERROR);
				degradationCallback();
			};
		});

		// Build a finally chain into the wrapped promise so if it resolves early, we cancel the degradation
		const wrappedFinally = wrapped.finally(() => {
			if (this.degradeTimeout) {
				clearTimeout(this.degradeTimeout);
			}
		});

		const wrapper = Promise.race<T>([wrappedFinally, degrader]);

		this.degradablePromiseInternal = wrapper;

		this.degradeTimeout = setTimeout(this.timeout, DEGRADE_TIMEOUT_MS);
	}

	private timeout: () => void = () => {
		this.degradeInternal();
	};

	public promise: () => Promise<T> = () => this.degradablePromiseInternal;

	public static from<T>(wrapped: Promise<T>, degradationCallback: DegradationCallback = () => {}) {
		return new DegradablePromise(wrapped, degradationCallback);
	}
}
