import React from 'react';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import {
	DEFAULT_GAS_CHANNEL,
	type LimitedGasPayload,
	type LimitedGasScreenEventPayload,
} from './events';

export const useAnalytics = () => {
	const { createAnalyticsEvent } = useAnalyticsEvents();

	return {
		fireAnalyticsEvent: React.useCallback(
			(payload: LimitedGasPayload | LimitedGasScreenEventPayload) =>
				createAnalyticsEvent(payload).fire(DEFAULT_GAS_CHANNEL),
			[createAnalyticsEvent],
		),
	};
};
