/**
 * Simple implementation of djb2 hashing.
 * The reason we have it internally is because at the moment we can't add new
 * dependencies into search-client package as it triggers a deprecated VR test in Editor
 * and it is locked down. So it fails to resolve the new dependency
 */
export const generateHash = (query: string) => {
	let hash = 5381;
	for (let i = 0; i < query.length; i++) {
		const char = query.charCodeAt(i);
		hash = (hash << 5) + hash + char; /* hash * 33 + char */
	}
	return hash;
};

export const generateHashFromVariables = (variables: Record<string, any>) => {
	try {
		return generateHash(JSON.stringify(variables)).toString();
	} catch (e) {
		return '';
	}
};
