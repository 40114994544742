type Events = Record<string, string>;

export const valueSeparator = '---';
export const attributeSeparator = '___';

export const parseAnalyticsFromQueryParams = (additionalAnalytics?: string | null) => {
	const formattedEvents = {} as Events;
	if (typeof additionalAnalytics !== 'string') {
		return formattedEvents;
	}

	const events = additionalAnalytics?.split('____');
	events.forEach((event) => {
		const formattedEvent = event?.split('---');
		const hasProperlyFormattedEvent = formattedEvent.length === 2;

		if (hasProperlyFormattedEvent) {
			formattedEvents[formattedEvent[0]] = formattedEvent[1];
		}
	});
	return formattedEvents;
};
