/**
 * Note all events fired include the searchSessionId, experiment-id, queryHash, queryVersion, wordCount, resultCount where applicable
 */

import { type GasPayload, type GasScreenEventPayload } from '@atlaskit/analytics-gas-types';
import { type ABTest } from '../clients';
import { type ProductFilterType } from '../clients/common-types';
import { type FilterOptionSource } from '../filters/types';
import { type Products } from '../product-context';
import { type OptionData, type Option } from '@atlaskit/smart-user-picker';
import { type FrontendExperiments } from '@atlassian/search-experiment';

export const DEFAULT_GAS_CHANNEL = 'fabric-elements';

export enum Trigger {
	SHORTCUT = 'shortcut',
	SHORTCUT_MODIFIED = 'shortcutModified',
	RETURN = 'return',
	CLICK = 'click',
	TEXT_ENTERED = 'textEntered',
}

export enum AdvancedSearchLinkSubjectId {
	CONFLUENCE = 'confluenceAdvancedSearchLink',
	CONFLUENCE_PEOPLE = 'confluencePeopleSearchLink',
	JIRA_ISSUES = 'jiraIssuesSearchLink',
	JIRA_BOARDS = 'jiraBoardsSearchLink',
	JIRA_FILTERS = 'jiraFiltersSearchLink',
	JIRA_PROJECTS = 'jiraProjectsSearchLink',
	JIRA_PLANS = 'jiraPlansSearchLink',
	JIRA_PEOPLE = 'jiraPeopleSearchLink',
	NO_RESULTS = 'noResultsAdvancedSearchLink',
}

export interface NonPrivacySafeContext {
	query: string;
}

export interface FiltersInfo {
	applied: {
		id: string;
		index: number;
		source: FilterOptionSource;
	}[];
	recommendedIds: {
		id: string;
		source: FilterOptionSource;
	}[];
}

export interface FiltersAnalyticsContext {
	container?: FiltersInfo;
	contributor?: FiltersInfo;
	projects?: FiltersInfo;
	assignees?: FiltersInfo;
	binaryStatusCategories?: FiltersInfo;
	dateFrom?: FiltersInfo;
	dateTo?: FiltersInfo;
}

export interface AdvancedSearchAnalyticsProps {
	actionSubjectId: string;
	isLoading: boolean;
	newTab: boolean;
	trigger: Trigger;
}

export interface MultiSiteAdvancedSearchAnalyticsProps {
	trigger: Trigger;
	actionSubjectId: string;
	isLoading: boolean;
	newTab: boolean;
	destinationId: string;
	index: number;
}

export interface MoreFiltersAnalyticsProps {
	trigger: Trigger;
	selectedFiltersCount: number;
}

export interface TabSelectedProps {
	tabName: Products;
}

const BASE_ANALYTICS_SOURCE = 'searchDialog';

export type ContextPayload = Partial<{
	abTest: ABTest;
	frontendExperiments: FrontendExperiments | { activeExperiments: string[] };
	searchSessionId: string;
	queryHash: string;
	queryLength: number;
	queryVersion: number;
	wordCount: number;
	frontend: string;
	collabGraphSessionId?: string;
	isSticky?: boolean;
	isStickyUpdated?: boolean;
	stickyQueryUpdated?: boolean;
	queryUpdatedAgo?: number;
	isQuickFind?: boolean;
}>;

export type GasPayloadWithContext = GasPayload;

/**
 * Custom GasPayload type with restrictions so we don't include common attributes that will be added as part of AnalyticContexts
 */
export type LimitedGasPayload = GasPayload & {
	attributes?: {
		[P in keyof ContextPayload]: never;
	} & {
		[key: string]: any;
	};
};

export type LimitedGasScreenEventPayload = GasScreenEventPayload & {
	attributes?: {
		[P in keyof ContextPayload]: never;
	} & {
		[key: string]: any;
	};
};

export const onAdvancedSearchSelected = ({
	trigger,
	actionSubjectId,
	isLoading,
	newTab,
}: AdvancedSearchAnalyticsProps): LimitedGasPayload => {
	return {
		action: 'selected',
		actionSubject: 'advancedSearchLink',
		actionSubjectId,
		source: BASE_ANALYTICS_SOURCE,
		eventType: 'track',
		attributes: {
			trigger,
			isLoading,
			newTab,
		},
	};
};

export const onAskAtlassianIntelligenceSelected = ({
	trigger,
	actionSubjectId,
	isLoading,
	newTab,
}: AdvancedSearchAnalyticsProps): LimitedGasPayload => {
	return {
		action: 'selected',
		actionSubject: 'askAtlassianIntelligenceLink',
		actionSubjectId,
		source: BASE_ANALYTICS_SOURCE,
		eventType: 'track',
		attributes: {
			trigger,
			isLoading,
			newTab,
		},
	};
};

export interface XButtonAnalyticsProps {
	actionSubjectId: string;
	searchSessionId: string;
}

export const onXButtonClicked = ({
	actionSubjectId,
	searchSessionId,
}: XButtonAnalyticsProps): GasPayloadWithContext => {
	return {
		action: 'dismissed',
		actionSubject: 'stickySearch',
		actionSubjectId,
		source: BASE_ANALYTICS_SOURCE,
		eventType: 'ui',
		attributes: {
			searchSessionId,
		},
	};
};

export const onNonDebouncedSearchTriggered = (): GasPayloadWithContext => {
	return {
		action: 'triggered',
		actionSubject: 'nonDebouncedSearch',
		source: BASE_ANALYTICS_SOURCE,
		eventType: 'ui',
	};
};

export interface StickySearchClearedAnalyticsProps {
	timeout: number;
}

export const onStickySearchCleared = ({
	timeout,
}: StickySearchClearedAnalyticsProps): GasPayloadWithContext => {
	return {
		eventType: 'track',
		action: 'cleared',
		actionSubject: 'stickySearch',
		source: BASE_ANALYTICS_SOURCE,
		attributes: {
			timeout,
		},
	};
};

interface AutocorrectAnalyticsProps {
	source: string;
	attributes: any;
}

export const onAutocorrectShown = ({
	source,
	attributes,
}: AutocorrectAnalyticsProps): GasPayloadWithContext => {
	return {
		eventType: 'ui',
		action: 'shown',
		actionSubject: 'link',
		actionSubjectId: 'autocorrect',
		source: source,
		attributes: attributes,
	};
};

export const onAutocorrectClicked = ({
	source,
	attributes,
}: AutocorrectAnalyticsProps): GasPayloadWithContext => {
	return {
		eventType: 'ui',
		action: 'clicked',
		actionSubject: 'link',
		actionSubjectId: 'autocorrect',
		source: source,
		attributes: attributes,
	};
};

export const onHoverPreviewShow = ({
	actionSubjectId,
}: {
	actionSubjectId: string;
}): GasPayloadWithContext => ({
	eventType: 'ui',
	source: BASE_ANALYTICS_SOURCE,
	action: 'show',
	actionSubject: 'hoverPreview',
	actionSubjectId,
});

export const onHoverPreviewShown = ({
	actionSubjectId,
}: {
	actionSubjectId: string;
}): GasPayloadWithContext => ({
	eventType: 'ui',
	source: BASE_ANALYTICS_SOURCE,
	action: 'shown',
	actionSubject: 'hoverPreview',
	actionSubjectId,
});

export const onHoverPreviewClicked = ({
	actionSubjectId,
}: {
	actionSubjectId: string;
}): GasPayloadWithContext => ({
	eventType: 'ui',
	source: BASE_ANALYTICS_SOURCE,
	action: 'clicked',
	actionSubject: 'hoverPreview',
	actionSubjectId,
});

export const onCardRequestFailed = ({
	attributes,
}: {
	attributes: { errorMsg?: string; errorName?: string };
}): GasPayloadWithContext => ({
	eventType: 'operational',
	source: BASE_ANALYTICS_SOURCE,
	action: 'failed',
	actionSubject: 'trelloCardRequest',
	attributes: {
		...attributes,
	},
});

export const onSearchResultViewAllLinkClicked = (sectionId: string): GasPayloadWithContext => ({
	eventType: 'ui',
	action: 'clicked',
	actionSubject: 'link',
	actionSubjectId: 'searchResultViewAllLink',
	attributes: {
		sectionId,
	},
});

export const onMultiSiteAdvancedSearchSelected = ({
	trigger,
	actionSubjectId,
	isLoading,
	newTab,
	destinationId,
	index,
}: MultiSiteAdvancedSearchAnalyticsProps): LimitedGasPayload => {
	return {
		action: 'selected',
		actionSubject: 'advancedSearchLink',
		actionSubjectId,
		source: BASE_ANALYTICS_SOURCE,
		eventType: 'ui',
		attributes: {
			trigger,
			isLoading,
			newTab,
			destinationId,
			index,
		},
	};
};

export const onMoreFiltersSelected = ({
	trigger,
	selectedFiltersCount,
}: MoreFiltersAnalyticsProps): LimitedGasPayload => {
	return {
		action: 'selected',
		actionSubject: 'moreFilterLink',
		source: BASE_ANALYTICS_SOURCE,
		eventType: 'ui',
		attributes: {
			trigger,
			selectedFiltersCount,
		},
	};
};

export type ScreenType = 'preQuerySearchResults' | 'postQuerySearchResults' | 'cachedResults';
export type SectionId = 'page';

export type SectionID =
	| 'confluence-item'
	| 'confluence-space'
	| 'confluence-people'
	| 'jira-issue'
	| 'jira-board-project-filter'
	| string;

export type SearchResultContextPayload = {
	sectionIndex: number;
	sectionId: string;
	containerId: string | null;
	globalIndex: number;
	indexWithinSection: number;
	isCached: boolean;
	metadata?: object;
};

export type OnSearchResultSelectedProps = {
	screen: ScreenType;
	actionSubject: string;
};

export const onSearchResultSelected = ({
	screen,
	actionSubject,
}: OnSearchResultSelectedProps): LimitedGasPayload => {
	return {
		action: 'selected',
		actionSubject: actionSubject,
		actionSubjectId: screen,
		source: BASE_ANALYTICS_SOURCE,
		eventType: 'track',
		attributes: {},
	};
};

export type OnSearchResultHighlightedProps = Omit<
	OnSearchResultSelectedProps,
	'isSticky' | 'isStickyUpdated' | 'stickyQueryUpdated'
>;

export const onSearchResultHighlighted = ({
	screen,
	actionSubject,
}: OnSearchResultHighlightedProps): LimitedGasPayload => {
	return {
		action: 'highlighted',
		actionSubject: actionSubject,
		actionSubjectId: screen,
		source: BASE_ANALYTICS_SOURCE,
		eventType: 'ui',
		attributes: {},
	};
};

export type OnSearchResultContextProps = OnSearchResultSelectedProps;

export const onSearchResultContext = ({
	screen,
	actionSubject,
}: OnSearchResultContextProps): LimitedGasPayload => {
	return {
		action: 'contextMenu opened',
		actionSubject: actionSubject,
		actionSubjectId: screen,
		source: BASE_ANALYTICS_SOURCE,
		eventType: 'ui',
		attributes: {},
	};
};

export enum ResultsShownActionSubjectId {
	PREQUERY = 'preQuerySearchResults',
	POSTQUERY = 'postQuerySearchResults',
	CACHED = 'cachedResults',
}

export interface ResultAnalyticData {
	containerId: string;
	isRecentResult: boolean;
	resultContentId: string;
	resultType: string;
}

export interface ResultSectionAnalyticData {
	results?: ResultAnalyticData[];
	sectionId: SectionID;
}

export interface FiltersForAnalytics {
	[filterName: string]: FiltersInfo;
}

export interface ResultsShownAnalyticsProps {
	actionSubjectId: 'preQuerySearchResults' | 'postQuerySearchResults' | 'cachedResults';
	resultCount: number;
	sectionCount: number;
	results: ResultSectionAnalyticData[];
	timeToQueryMs?: number;
	activeProduct: string;
	isMultiProduct: boolean;
	filters?: FiltersForAnalytics;
}

export const onSearchResultsShown = ({
	actionSubjectId,
	resultCount,
	sectionCount,
	results,
	timeToQueryMs,
	activeProduct,
	isMultiProduct,
	filters,
}: ResultsShownAnalyticsProps): LimitedGasPayload => {
	return {
		action: 'shown',
		actionSubject: 'searchResults',
		actionSubjectId,
		source: BASE_ANALYTICS_SOURCE,
		eventType: 'ui',
		attributes: {
			resultCount,
			sectionCount,
			timeToQueryMs,
			results,
			noResultsFetched: !resultCount,
			activeProduct,
			isMultiProduct,
			filters,
		},
	};
};

export const onTextEntered = (): LimitedGasPayload => {
	return {
		action: 'entered',
		actionSubject: 'text',
		actionSubjectId: 'searchDialogTextField',
		source: BASE_ANALYTICS_SOURCE,
		eventType: 'track',
		attributes: {},
	};
};

export interface OnShowMoreClickedProps {
	total: number;
	currentSize: number;
	pageSize: number;
}

export const onShowMoreClicked = ({
	total,
	currentSize,
	pageSize,
}: OnShowMoreClickedProps): LimitedGasPayload => {
	return {
		action: 'clicked',
		actionSubject: 'button',
		actionSubjectId: 'showMoreButton',
		source: BASE_ANALYTICS_SOURCE,
		eventType: 'ui',
		attributes: {
			total,
			currentSize,
			pageSize,
		},
	};
};

export interface OnShowAllClickedProps {
	total: number;
	currentSize: number;
	pageSize: number;
}

export const onShowAllClicked = ({
	total,
	currentSize,
	pageSize,
}: OnShowMoreClickedProps): LimitedGasPayload => {
	return {
		action: 'clicked',
		actionSubject: 'button',
		actionSubjectId: 'showAllLink',
		source: BASE_ANALYTICS_SOURCE,
		eventType: 'ui',
		attributes: {
			total,
			currentSize,
			pageSize,
		},
	};
};

export interface OnPreQueryScreenViewedProps {
	searchSessionId: string;
	trigger: Trigger;
}

export const onPreQueryScreenViewed = ({
	searchSessionId,
	trigger,
}: OnPreQueryScreenViewedProps): GasScreenEventPayload => {
	return {
		name: 'searchDialogPreQueryScreen',
		eventType: 'screen',
		attributes: {
			searchSessionId,
			trigger,
		},
	};
};

export interface RequestMadeAnalyticsProps {
	actionSubjectId: 'preQuerySearchResults' | 'postQuerySearchResults';
	timings: object;
	activeProduct?: string;
	isExpanded?: boolean;
	isMultiProduct?: boolean;
	filters?: FiltersForAnalytics;
}

/**
 * We allow onRequestMade to be given an explicit context because it gets fired at the same level as the query contexts so it won't normally pick it up
 */
export const onRequestMade = (
	{
		actionSubjectId,
		timings,
		activeProduct,
		isExpanded,
		isMultiProduct,
		filters,
	}: RequestMadeAnalyticsProps,
	additionalContext: ContextPayload = {},
	nonPrivacySafeAttributes: NonPrivacySafeContext = { query: '' },
): GasPayloadWithContext => {
	return {
		action: 'completed',
		actionSubject: 'searchRequest',
		actionSubjectId,
		source: BASE_ANALYTICS_SOURCE,
		eventType: 'track',
		attributes: {
			activeProduct,
			isExpanded,
			isMultiProduct,
			filters,
			...timings,
			...additionalContext,
		},
		nonPrivacySafeAttributes,
	};
};

export const onPreQueryEmpty = (): GasPayloadWithContext => ({
	action: 'shown',
	eventType: 'ui',
	actionSubject: 'searchResults',
	actionSubjectId: 'preQuerySearchResults',
	attributes: {
		resultCount: 0,
		results: [],
	},
	source: BASE_ANALYTICS_SOURCE,
});

export const onDismissed = (): LimitedGasPayload => {
	return {
		action: 'dismissed',
		actionSubject: 'searchDialog',
		source: BASE_ANALYTICS_SOURCE,
		eventType: 'ui',
	};
};

export interface OnShownProps {
	visibleProducts: string[];
	visibleTabsCount: number;
}

export const onShown = ({ visibleProducts, visibleTabsCount }: OnShownProps): LimitedGasPayload => {
	return {
		action: 'shown',
		actionSubject: 'searchDialog',
		source: BASE_ANALYTICS_SOURCE,
		eventType: 'ui',
		attributes: {
			visibleProducts,
			visibleTabsCount,
		},
	};
};

export const onDropdownMenuFilterView = ({
	filterType,
}: {
	filterType: ProductFilterType | string;
}): LimitedGasPayload => {
	return {
		action: 'viewed',
		actionSubject: 'filterField',
		actionSubjectId: 'dropdownMenuFilterField',
		source: BASE_ANALYTICS_SOURCE,
		eventType: 'ui',
		attributes: {
			filterType,
		},
	};
};

export interface FilterSelectedProps {
	filterId: string;
	filterSource: FilterOptionSource;
	filterType: ProductFilterType | string;
}

export const onFilterSelect = ({
	filterId,
	filterType,
	filterSource,
}: FilterSelectedProps): LimitedGasPayload => {
	return {
		action: 'selected',
		actionSubject: 'filter',
		actionSubjectId: 'searchDialogFilter',
		source: BASE_ANALYTICS_SOURCE,
		eventType: 'ui',
		attributes: {
			filterId,
			filterType,
			filterSource,
		},
	};
};

export const onFilterUnselect = ({
	filterId,
	filterType,
	filterSource,
}: FilterSelectedProps): LimitedGasPayload => {
	return {
		action: 'unselected',
		actionSubject: 'filter',
		actionSubjectId: 'searchDialogFilter',
		source: BASE_ANALYTICS_SOURCE,
		eventType: 'ui',
		attributes: {
			filterId,
			filterType,
			filterSource,
		},
	};
};

export const onFilterFieldCleared = ({
	filterType,
}: {
	filterType: ProductFilterType | string;
}): LimitedGasPayload => {
	return {
		action: 'cleared',
		actionSubject: 'filterField',
		actionSubjectId: 'searchDialogFilterField',
		source: BASE_ANALYTICS_SOURCE,
		eventType: 'ui',
		attributes: {
			filterType,
		},
	};
};

export const onFiltersCleared = (): LimitedGasPayload => {
	return {
		action: 'clicked',
		actionSubject: 'button',
		actionSubjectId: 'searchDialogClearFilters',
		source: BASE_ANALYTICS_SOURCE,
		eventType: 'ui',
		attributes: {},
	};
};

export const onPaneLoadComplete = (product: string, duration: number): LimitedGasPayload => {
	return {
		action: 'loadComplete',
		actionSubject: 'searchDialogPane',
		actionSubjectId: product,
		source: BASE_ANALYTICS_SOURCE,
		eventType: 'track',
		attributes: {
			durationMs: duration,
		},
	};
};

export const onExperimentLoaded = (
	abTest: ABTest,
	experimentRequestDuration: number,
	isMultiProduct: boolean,
): GasPayloadWithContext => {
	return {
		action: 'loaded',
		actionSubject: 'experiment',
		source: BASE_ANALYTICS_SOURCE,
		eventType: 'operational',
		attributes: {
			abTest,
			experimentRequestDuration,
			isMultiProduct,
		},
	};
};

export const onTabSelected = (
	{ tabName }: TabSelectedProps,
	additionalContext: ContextPayload = {},
	nonPrivacySafeAttributes: NonPrivacySafeContext = { query: '' },
): GasPayloadWithContext => {
	// this event is fired at the same level as the anlytics context hence it needs mandatory additional objects.
	return {
		action: 'clicked',
		actionSubject: 'tab',
		actionSubjectId: 'crossProductSearchTab',
		source: BASE_ANALYTICS_SOURCE,
		eventType: 'ui',
		attributes: {
			tabName,
			...additionalContext,
		},
		nonPrivacySafeAttributes,
	};
};

export const onThirdPartyAuthorisedCheck = (
	authorised: boolean,
	product: string,
): GasPayloadWithContext => {
	return {
		action: 'checked',
		actionSubject: 'thirdPartyAuth',
		actionSubjectId: product,
		source: BASE_ANALYTICS_SOURCE,
		eventType: 'track',
		attributes: {
			authorised,
		},
	};
};

export const onThirdPartyAuthorised = (product: string): GasPayloadWithContext => {
	return {
		action: 'authorised',
		actionSubject: 'thirdPartyProduct',
		actionSubjectId: product,
		source: BASE_ANALYTICS_SOURCE,
		eventType: 'track',
		attributes: {},
	};
};

// Smart User Picker analytics
// These analytics will be used to join with the backend collaboration graph analytics to identify
// if our model is performing well.

export type AtlaskitSelectValue = Option | Array<Option> | null | undefined;

const createEvent = (
	eventType: 'ui' | 'operational',
	action: string,
	actionSubject: string,
	attributes = {},
): LimitedGasPayload => ({
	eventType,
	action,
	actionSubject,
	attributes: {
		...attributes,
	},
});

const position = (options: OptionData[], value?: Option) => {
	return value ? options.findIndex((option) => option.id === value.data.id) : -1;
};

const result = (option?: Option) => {
	return option ? optionData2Analytics(option.data) : null;
};

const optionData2Analytics = (option: OptionData) => {
	const { id, type } = option;
	return { id: id ? id : null, type: type || null };
};

const createDefaultPickerAttributes = (fieldId: string, collabGraphSessionId: string) => ({
	context: fieldId,
	isCheckbox: true,
	queryLength: 0,
	collabGraphSessionId,
});

export const selectEvent = (
	value: Option,
	options: OptionData[],
	fieldId: string,
	collabGraphSessionId: string,
) => {
	return createEvent('ui', 'clicked', 'userPicker', {
		...createDefaultPickerAttributes(fieldId, collabGraphSessionId),
		position: position(options, value),
		result: result(value),
	});
};

export const onRecentSearchSelected = (): LimitedGasPayload => {
	return {
		action: 'clicked',
		actionSubject: 'button',
		actionSubjectId: 'recentSearch',
		source: BASE_ANALYTICS_SOURCE,
		eventType: 'ui',
		attributes: {},
	};
};

// The following are used to track engagement with onboarding components for SAIN (smart answers) in Confluence quick search

export const onPrequeryBannerShown = (): LimitedGasPayload => {
	return {
		action: 'shown',
		actionSubject: 'aiOnboardingPrompt',
		actionSubjectId: 'prequeryBanner',
		source: BASE_ANALYTICS_SOURCE,
		eventType: 'ui',
		attributes: {},
	};
};

export const onSuggestedPromptClick = (promptId: string): LimitedGasPayload => {
	return {
		action: 'clicked',
		actionSubject: 'aiOnboardingPrompt',
		actionSubjectId: promptId,
		source: BASE_ANALYTICS_SOURCE,
		eventType: 'ui',
		attributes: {},
	};
};

export const onPrequeryBannerDismiss = (): LimitedGasPayload => {
	return {
		action: 'clicked',
		actionSubject: 'aiOnboardingPrompt',
		actionSubjectId: 'dismissButton',
		source: BASE_ANALYTICS_SOURCE,
		eventType: 'ui',
		attributes: {},
	};
};

// Confluence reactivation experiment events

export const onConfluenceReactionNudgeShown = (neverActive: boolean): LimitedGasPayload => {
	return {
		action: 'shown',
		actionSubject: 'nudge',
		actionSubjectId: 'confluenceReactivation',
		source: BASE_ANALYTICS_SOURCE,
		eventType: 'ui',
		attributes: {
			touchpointId: 'search',
			neverActive,
		},
	};
};
