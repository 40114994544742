import { defineMessages } from 'react-intl-next';

export const messages = defineMessages({
	/**
	 * Product-agnostic messages
	 */
	common_updated_date_formatter: {
		id: 'product_search_dialog.updated_date_formatter',
		defaultMessage: 'Updated {date}',
		description: 'A text shown next to the date for the search results.',
	},
	common_recently_viewed_section_heading: {
		id: 'product_search_dialog.recently_viewed_section_heading',
		defaultMessage: 'Recently viewed',
		description: '',
	},
	common_recently_spaces_section_heading: {
		id: 'product_search_dialog.recent_spaces_section_heading',
		defaultMessage: 'Recent spaces',
		description: '',
	},
	common_recently_worked_with_section_heading: {
		id: 'product_search_dialog.recently_worked_with_section_heading',
		defaultMessage: 'Recently worked with',
		description: '',
	},
	common_error_state_failed_search_heading: {
		id: 'product_search_dialog.error.failed_loading_search_heading',
		defaultMessage: "We're having trouble loading your recently viewed content.",
		description: '',
	},
	common_error_state_failed_search_body: {
		id: 'product_search_dialog.error.failed_loading_search_body',
		defaultMessage:
			'It might just be a hiccup. Please try refreshing the page or <emptyLink>try again.</emptyLink>',
		description: '',
	},
	common_empty_state_heading: {
		id: 'product_search_dialog.empty.no_results_heading',
		defaultMessage: "We couldn't find anything matching your search.",
		description: '',
	},
	common_empty_state_prequery_heading: {
		id: 'product_search_dialog.empty_prequery.no_results_heading',
		defaultMessage: 'Search for what you need',
		description: '',
	},
	common_error_state_prequery_heading: {
		id: 'product_search_dialog.empty_prequery.error_heading',
		defaultMessage: 'Something went wrong with showing initial results',
		description: '',
	},
	common_show_more_results_link: {
		id: 'product_search_dialog.show_more_results_link',
		defaultMessage: 'Show more',
		description: '',
	},
	common_show_all_results_link: {
		id: 'product_search_dialog.show_all_results_link',
		defaultMessage: 'View all results',
		description: '',
	},
	common_search_people_link: {
		id: 'product_search_dialog.search_people_link',
		defaultMessage: 'Search people',
		description: '',
	},
	common_search_input_collapsed_placeholder: {
		id: 'product_search_dialog.search_input_collapsed_placeholder',
		defaultMessage: 'Search',
		description: '',
	},
	common_search_input_enlarged_aria_label: {
		id: 'product_search_dialog.search_input_enlarged_aria_label',
		defaultMessage: 'Press enter to navigate to advanced search',
		description: 'used for accessibility for the EnlargedSearchInput component',
	},
	common_empty_state_body: {
		id: 'product_search_dialog.empty.no_results_body',
		defaultMessage:
			'Try again with a different term, or refine your results with <a>Advanced Search</a>',
		description: '',
	},
	common_empty_state_body_no_advanced_search: {
		id: 'product_search_dialog.empty.no_results_body_with_no_advanced_search',
		defaultMessage: 'Try again with a different term.',
		description: '',
	},
	common_give_feedback: {
		id: 'product_search_dialog.give_feedback',
		defaultMessage: 'Give feedback',
		description:
			'Button that looks like a link that the user clicks to open a dialog to give feedback about search',
	},
	common_inline_onboarding_title: {
		id: 'product_search_dialog.inline_onboarding_title',
		defaultMessage: 'Search across Jira and Confluence',
		description:
			'Heading of the inline section message which educates first time users about cross product search tabs',
	},
	common_inline_onboarding_body: {
		id: 'product_search_dialog.inline_onboarding_body',
		defaultMessage:
			'Use the Jira and Confluence tabs to search and see recent items in either location.',
		description:
			'Description of the inline section message which educates first time users about cross product search tabs',
	},
	common_inline_onboarding_action: {
		id: 'product_search_dialog.inline_onboarding_action',
		defaultMessage: 'Ok',
		description: 'A button that looks like a link which suspends the section message.',
	},
	common_search_input_exit_button_label: {
		id: 'search_dialog.search_input_exit_label',
		defaultMessage: 'Exit Search',
		description: 'Label for the back button to exit search',
	},
	common_search_input_clear_button_label: {
		id: 'search_dialog.search_input_clear_label',
		defaultMessage: 'Clear search session',
		description: 'Label for the x button to clear the search input',
	},
	multi_site_advanced_search_label: {
		id: 'product_search_dialog.multi_site_advanced_search_label',
		defaultMessage: 'Search on:',
		description: 'Label for a list of site links in advanced search',
	},
	multi_site_advanced_search_more_dropdown: {
		id: 'product_search_dialog.multi_site_advanced_search_more_dropdown',
		defaultMessage: '{count} more',
		description: 'A dropdown which opens a list of links to sites to advanced search in',
	},
	common_autocorrection_label: {
		id: 'product_search_dialog.autocorrection.label',
		defaultMessage: 'Did you mean',
		description: 'Label text for the autocorrection component',
	},
	/**
	 * Cross product
	 */
	jira_tab_label: {
		id: 'cross_product_search_dialog.jira.tab_label',
		defaultMessage: 'Jira',
		description: '',
	},
	confluence_tab_label: {
		id: 'cross_product_search_dialog.confluence.tab_label',
		defaultMessage: 'Confluence',
		description: '',
	},
	atlas_tab_label: {
		id: 'cross_product_search_dialog.atlas.tab_label',
		defaultMessage: 'Atlas',
		description: 'The name of the Atlas product',
	},
	bitbucket_tab_label: {
		id: 'cross_product_search_dialog.bitbucket.tab_label',
		defaultMessage: 'Bitbucket',
		description: '',
	},
	opsgenie_tab_label: {
		id: 'cross_product_search_dialog.opsgenie.tab_label',
		defaultMessage: 'Opsgenie',
		description: '',
	},
	trello_tab_label: {
		id: 'cross_product_search_dialog.trello.tab_label',
		defaultMessage: 'Trello',
		description: '',
	},
	view_all_results_link: {
		id: 'cross_product_search_dialog.view_all_results_link',
		defaultMessage: 'View all {sectionTitle}',
		description: '',
	},
	recently_viewed_title: {
		id: 'cross_product_search_dialog.recently_viewed_title',
		defaultMessage: 'Recently Viewed {sectionTitle}',
		description: '',
	},
	/**
	 * Common Confluence Messages
	 */
	confluence_people_section_heading: {
		id: 'product_search_dialog.confluence.people_section_heading',
		defaultMessage: 'People',
		description: '',
	},
	confluence_content_section_heading: {
		id: 'product_search_dialog.confluence.content_section_heading',
		defaultMessage: 'Content',
		description:
			'Section label for pages, blogs, attachments, and whiteboards in quick search dialog',
	},
	confluence_spaces_section_heading: {
		id: 'product_search_dialog.confluence.spaces_section_heading',
		defaultMessage: 'Spaces',
		description: '',
	},
	confluence_search_input_expanded_placeholder: {
		id: 'product_search_dialog.confluence.search_input_expanded_placeholder',
		defaultMessage: 'Search Confluence',
		description: '',
	},
	confluence_search_input_expanded_placeholder_ai: {
		id: 'product_search_dialog.confluence.search_input_expanded_placeholder_ai',
		defaultMessage: 'Search or ask questions within your Confluence',
		description: '',
	},
	confluence_pages_blogs_attachments_section_heading: {
		id: 'product_search_dialog.confluence.pages_blogs_attachments_section_heading',
		defaultMessage: 'Pages, blogs and attachments',
		description: '',
	},
	/**
	 * Common Filtering Messages
	 */
	filters_applied: {
		id: 'product_search_dialog.confluence.filters_applied',
		defaultMessage: 'Looks like you have some filters applied',
		description: 'Message to show when there are no search results due to filters being applied',
	},
	clear_filters: {
		id: 'product_search_dialog.confluence.clear_filters',
		defaultMessage: 'Clear filters',
		description: 'Message to be displayed on button to clear the filters applied',
	},
	show_more_filters: {
		id: 'search_dialog.filters.group.show-more',
		defaultMessage: 'Show more',
		description: 'Text display on button to select more items to filter by',
	},
	more_filters: {
		id: 'product_search_dialog.more_filters',
		defaultMessage: 'View all filters',
		description: 'Clicking on this link takes a user to a page with more filters and options.',
	},
	no_options_filters: {
		id: 'product_search_dialog.no_options_filters',
		defaultMessage: 'No options',
		description: 'Message to show when no results are returned when filtering',
	},
	loading_filters: {
		id: 'product_search_dialog.loading_filters',
		defaultMessage: 'Loading',
		description: 'Message to show when filter results are loading',
	},
	site_filters_title: {
		id: 'product_search_dialog.common.site_filters',
		defaultMessage: 'Filter by site',
		description: 'Section title for site filters',
	},
	space_filters_title: {
		id: 'product_search_dialog.confluence.space_filters',
		defaultMessage: 'Filter by space',
		description: 'Section title for space filters in Confluence',
	},
	contributor_filters_title: {
		id: 'product_search_dialog.confluence.contributor_filters',
		defaultMessage: 'Filter by contributor',
		description: 'Section title for contributor filters in Confluence',
	},
	contributor_filters_find_more: {
		id: 'product_search_dialog.confluence.contributor_filters_find_more',
		defaultMessage: 'Find a contributor',
		description: 'Placeholder text for select menu when finding a contributor to filter results by',
	},
	space_filters_find_more: {
		id: 'product_search_dialog.confluence.space_filters_find_more',
		defaultMessage: 'Find a space',
		description: 'Placeholder text for select menu when finding a space to filter results by',
	},
	site_filters_find_more: {
		id: 'product_search_dialog.jira.site_filters_find_more',
		defaultMessage: 'Find a site',
		description: 'Placeholder text for select menu when finding a site to filter results by',
	},
	project_filters_find_more: {
		id: 'product_search_dialog.jira.project_filters_find_more',
		defaultMessage: 'Find a project',
		description: 'Placeholder text for select menu when finding a project to filter results by',
	},
	project_filters_title: {
		id: 'product_search_dialog.jira.project_filters',
		defaultMessage: 'Filter by project',
		description: 'Section title for project filters in Jira',
	},
	assignee_filters_find_more: {
		id: 'product_s earch_dialog.confluence.assignee_filters_find_more',
		defaultMessage: 'Find an assignee',
		description: 'Placeholder text for select menu when finding a assignee to filter results by',
	},
	assignee_filters_title: {
		id: 'product_search_dialog.jira.assignee_filters',
		defaultMessage: 'Filter by assignee',
		description: 'Section title for assignee filters in Jira',
	},
	binary_status_category_filters_title: {
		id: 'product_search_dialog.jira.issue_filters',
		defaultMessage: 'Filter by status',
		description: 'Section title for issue filters in Jira',
	},
	binary_status_category_filter_option_done: {
		id: 'product_search_dialog.jira.issue_filter_done',
		defaultMessage: 'Done',
		description: 'Filter option title for done issues',
	},
	binary_status_category_filter_option_open: {
		id: 'product_search_dialog.jira.issue_filter_open',
		defaultMessage: 'Open',
		description: 'Filter option title for open issues',
	},
	date_filter_any_time_label: {
		id: 'product_search_dialog.jira.date_filter_any',
		defaultMessage: 'Any time',
		description: 'Date filter label for any time.',
	},
	date_filter_filters_title: {
		id: 'product_search_dialog.jira.date_filters',
		defaultMessage: 'Last updated',
		description: 'Section title for date filters in Jira',
	},
	/**
	 * Common Jira Messages
	 */
	jira_advanced_search_label: {
		id: 'global_search.jira.advanced_search_label',
		defaultMessage: 'Go to all:',
		description: '',
	},
	jira_advanced_search_issues: {
		id: 'global_search.jira.advanced_search_issues',
		defaultMessage: 'Issues',
		description: 'Plural of issue',
	},
	jira_advanced_search_projects: {
		id: 'global_search.jira.advanced_search_projects',
		defaultMessage: 'Projects',
		description: 'Plural of project',
	},
	jira_advanced_search_boards: {
		id: 'global_search.jira.advanced_search_boards',
		defaultMessage: 'Boards',
		description: 'Plural of board',
	},
	jira_advanced_search_filters: {
		id: 'global_search.jira.advanced_search_filters',
		defaultMessage: 'Filters',
		description: 'Plural of filter',
	},
	jira_advanced_search_plans: {
		id: 'global_search.jira.advanced_search_plans',
		defaultMessage: 'Plans',
		description: 'Plural of plan',
	},
	jira_advanced_search_people: {
		id: 'global_search.jira.advanced_search_people',
		defaultMessage: 'People',
		description: '',
	},
	jira_empty_state_prequery_heading: {
		id: 'product_search_dialog.jira.empty_prequery.no_results_heading',
		defaultMessage: 'Start searching to find your work',
		description:
			'This message is shown when the user has no recently viewed issues and has not yet typed a search query',
	},
	jira_issues_section_heading: {
		id: 'product_search_dialog.jira.issues_section_heading',
		defaultMessage: 'Issues',
		description: '',
	},
	jira_boards_projects_filters_section_heading: {
		id: 'product_search_dialog.jira.boards_projects_filters_section_heading',
		defaultMessage: 'Boards, Projects and Filters',
		description: '',
	},
	jira_projects_filters_section_heading: {
		id: 'product_search_dialog.jira.projects_filters_section_heading',
		defaultMessage: 'Projects and Filters',
		description: '',
	},
	jira_boards_projects_filters_plans_section_heading: {
		id: 'product_search_dialog.jira.boards_projects_filters_plans_section_heading',
		defaultMessage: 'Boards, Projects, Filters and Plans',
		description: 'Title for search results of Jira entities (Boards, Projects, Filters, and Plans)',
	},
	jira_view_all_issues_new: {
		id: 'product_search_dialog.jira.jira_view_all_issues_new',
		defaultMessage: 'View all issues',
		description:
			'Text for link that takes users to advanced search for issues - renamed from "Advanced issue search"',
	},
	jira_recent_boards_projects_filters_section_heading: {
		id: 'product_search_dialog.jira.recent_boards_projects_filters_section_heading',
		defaultMessage: 'Recent Boards, Projects and Filters',
		description: '',
	},
	jira_recent_projects_filters_section_heading: {
		id: 'product_search_dialog.jira.recent_projects_filters_section_heading',
		defaultMessage: 'Recent Projects and Filters',
		description: '',
	},
	jira_recent_boards_projects_filters_plans_section_heading: {
		id: 'product_search_dialog.jira.recent_boards_projects_filters_plans_section_heading',
		defaultMessage: 'Recent Boards, Projects, Filters and Plans',
		description: 'Title for recently accessed Jira entities (Boards, Projects, Filters, and Plans)',
	},
	jira_recently_viewed_issues_section_heading: {
		id: 'product_search_dialog.jira.recently_viewed_issues_section_heading',
		defaultMessage: 'Recently viewed issues',
		description: '',
	},
	jira_search_placeholder: {
		id: 'global_search.jira.search_placeholder',
		defaultMessage: 'Search Jira',
		description: 'Search field placeholder when the Jira tab is active',
	},
	jira_search_placeholder_experiment_jsw_enabled: {
		id: 'global_search.jira.search_placeholder_experiment_jsw_enabled',
		defaultMessage: 'Search for issues, boards, projects, and filters',
		description:
			'Search field placeholder when the Jira tab is active and the user has a JSW license but no access to advanced roadmaps',
	},
	jira_advanced_search_issuesIssueTermRefresh: {
		id: 'global_search.jira.advanced_search_issues-issue-term-refresh',
		defaultMessage: 'Work items',
		description: 'Plural of issue',
	},
	jira_issues_section_headingIssueTermRefresh: {
		id: 'product_search_dialog.jira.issues_section_heading-issue-term-refresh',
		defaultMessage: 'Work items',
		description: '',
	},
	jira_view_all_issues_newIssueTermRefresh: {
		id: 'product_search_dialog.jira.jira_view_all_issues_new-issue-term-refresh',
		defaultMessage: 'View all work items',
		description:
			'Text for link that takes users to advanced search for issues - renamed from "Advanced issue search"',
	},
	jira_recently_viewed_issues_section_headingIssueTermRefresh: {
		id: 'product_search_dialog.jira.recently_viewed_issues_section_heading-issue-term-refresh',
		defaultMessage: 'Recently viewed work items',
		description: '',
	},
	jira_search_placeholder_experiment_jsw_enabledIssueTermRefresh: {
		id: 'global_search.jira.search_placeholder_experiment_jsw_enabled-issue-term-refresh',
		defaultMessage: 'Search for work items, boards, projects, and filters',
		description:
			'Search field placeholder when the Jira tab is active and the user has a JSW license but no access to advanced roadmaps',
	},
});
