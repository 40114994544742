import { useIntl } from 'react-intl-next';
import debouncePromise from 'debounce-promise';
import { utils } from '@atlaskit/util-service-support';
import { useProductContext } from '../product-router';

enum SUPPORTED_LANGUAGES {
	English = 'en',
}

export const AUTOCORRECT_API_PATH = 'autocorrect';
export const DEFAULT_AUTOCORRECT_DEBOUNCE_TIME_MS = 250;

export const isSupportedLanguage = (language: string) => {
	return (Object.values(SUPPORTED_LANGUAGES) as string[]).includes(language);
};

export const sanitizeLanguage = (locale: string) => {
	if (locale.includes('-')) {
		return locale.split('-')[0];
	}
	return locale;
};

export const shouldFetchAutocorrection = (query: string) => {
	return query.length >= 4;
};

export const useAutocorrectionEnabled = () => {
	const { locale } = useIntl();
	const lang = sanitizeLanguage(locale);
	const { getActiveProduct } = useProductContext();
	const product = getActiveProduct();
	return {
		autocorrectionEnabled: !!product?.autocorrectEnabled && isSupportedLanguage(lang),
		productId: product?.id || '',
	};
};

type fetchAutocorrectionArgs = {
	query: string;
	aggregatorUrl: string;
	lang: string;
	cloudId: string;
};

export const fetchAutocorrection = debouncePromise(
	({ query, aggregatorUrl, lang, cloudId }: fetchAutocorrectionArgs) => {
		return utils.requestService<any>(
			{ url: aggregatorUrl },
			{
				path: `${AUTOCORRECT_API_PATH}`,
				requestInit: {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
					},
					body: JSON.stringify({
						query: query,
						language: lang,
						cloudId: cloudId,
					}),
				},
			},
		);
	},
	DEFAULT_AUTOCORRECT_DEBOUNCE_TIME_MS,
);
