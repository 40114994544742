import { type MultiLayerBackendExperiment } from '../types';

export const STATSIG_SEARCH_BACKEND_FEATURE_GATE_L1_STANDARD_VS_PREMIUM = 'l1_premium_vs_standard';
export const STATSIG_SEARCH_BACKEND_L1_LAYER_STANDARD = 'search_platform_layer_l1_standard';
export const STATSIG_SEARCH_BACKEND_L1_LAYER_PREMIUM = 'search_platform_layer_l1_premium';

export const STATSIG_SEARCH_BACKEND_FEATURE_GATE_L2_STANDARD_VS_PREMIUM = 'l2_premium_vs_standard';
export const STATSIG_SEARCH_BACKEND_L2_LAYER_STANDARD = 'search_platform_layer_l2_standard';
export const STATSIG_SEARCH_BACKEND_L2_LAYER_PREMIUM = 'search_platform_layer_l2_premium';
export const STATSIG_SEARCH_BACKEND_L2_BLENDING_LAYER = 'search_platform_layer_l2_blending';
export const STATSIG_SEARCH_BACKEND_L3_LAYER = 'search_platform_layer_l3';
export const STATSIG_SEARCH_BACKEND_QUERY_EXPANSION_LAYER = 'search_platform_layer_query_expansion';

export const STATSIG_SEARCH_BACKEND_FEATURE_GATE_L3_ENABLED = 'enable_l3';
export const STATSIG_SEARCH_BACKEND_FEATURE_GATE_QUERY_EXPANSION_ENABLED = 'enable_query_rewrite';

// https://console.statsig.com/LqivKg6ADZZaGczRfBKfX/layers/full_page_search_layer
export const STATSIG_FULL_PAGE_SEARCH_LAYER = 'full_page_search_layer';

// https://console.statsig.com/LqivKg6ADZZaGczRfBKfX/layers/quick_find_layer
export const STATSIG_QUICK_FIND_LAYER = 'quick_find_layer';

export const DEFAULT_STATSIG_EXPERIMENT_CONFIG = {
	experimentId: 'default',
	shadowExperimentId: 'default',
	controlId: 'default',
	abTestId: 'default',
} as const;

export const DEFAULT_STATSIG_PARALLEL_EXPERIMENTS_CONFIG: MultiLayerBackendExperiment = {
	experimentId: 'default',
	shadowExperimentId: 'default',
	experimentLayers: [],
} as const;
