import { fg } from '@atlaskit/platform-feature-flags';
import { type RequestServiceOptions, utils } from '@atlaskit/util-service-support';

import { generateHash } from '../../../utils/caching/common/key-generator';
import { promiseCache } from '../../../utils/caching/persist-refresh';
import { DegradablePromise } from '../../utils/degradable-promise';

const RELATIVE_URL = '/gateway/api/xpsearch-aggregator/configuration/v1';
const RELATIVE_URL_V2 = '/gateway/api/xpsearch-aggregator/api/v2/configuration';
const RELATIVE_3P_URL = '/gateway/api/third-party-configuration/connected-data-sources';
const RELATIVE_LOOM_AUTH_URL = '/gateway/api/loom-auth/api/internal/identity/user-status';

export const SEARCH_CONFIG = 'search-config';
export const SEARCH_3P_CONFIG = 'search-3p-config';
export const LOOM_USER_AUTH = 'loom-user-auth';

const { refreshOnAccessDecorator: refreshOnAccessDecoratorSearch } =
	promiseCache<TSearchConfigAPIResponse>({ name: SEARCH_CONFIG });

const { refreshOnAccessDecorator: refreshOnAccessDecorator3pSearch } =
	promiseCache<TSearch3pConfigAPIResponse>({
		name: SEARCH_3P_CONFIG,
		expiration: 1000 * 60 * 5,
	});

const { refreshOnAccessDecorator: refreshOnAccessDecoratorUserLoomAuth } =
	promiseCache<LoomUserAuthResponse>({
		name: LOOM_USER_AUTH,
	});

const SITELESS_PRODUCTS = ['trello', 'bitbucket'];

export type TSearchConfigProps = {
	/**
	 * When passed this would be used as is to invoke the endpoint.
	 * This is useful for products like Trello where the API gateway is not on the host domain, but a completely different URL.
	 *
	 * When specified it takes precedence over hostName.
	 */
	absoluteUrl?: string;

	/**
	 * When passed this would be used to invoke the endpoint. Example: https://product-fabric.atlassian.net
	 * This is used ONLY if absoluteUrl is not specified.
	 */
	hostName?: string;

	/**
	 * If passed then only the resources which are tied to the given cloudId are returned.
	 */
	cloudId?: string;

	/**
	 * Identifier of the user
	 */
	userId?: string | null;

	/**
	 * Determines if cached value should be refreshed if previous value has not expired yet
	 */
	onlyRefreshIfExpired?: boolean;

	/**
	 * Duration (in ms) that the result of this request should be cached for
	 */
	expiration?: number;

	/**
	 * Callback to execute when API is too slow and must degrade
	 */
	degradationCallback?: () => void;
};

export type TSearch3pConfigProps = {
	/**
	 * When passed this would be used as is to invoke the endpoint.
	 * This is useful for products like Trello where the API gateway is not on the host domain, but a completely different URL.
	 */
	absoluteUrl?: string;

	cloudId?: string;

	/**
	 * Identifier of the user
	 */
	userId?: string | null;

	/**
	 * If true, third party config fetch will be disabled
	 */
	disabled?: boolean;

	/**
	 * Determines if cached value should be refreshed if previous value has not expired yet
	 */
	onlyRefreshIfExpired?: boolean;

	/**
	 * Duration (in ms) that the result of this request should be cached for
	 */
	expiration?: number;
};
export type LoomUserAuthProps = {
	/**
	 * When passed this would be used as is to invoke the endpoint.
	 * This is useful for products like Trello where the API gateway is not on the host domain, but a completely different URL.
	 */
	absoluteUrl?: string;

	cloudId?: string;

	/**
	 * Identifier of the user
	 */
	userId?: string | null;
};

export type TResource = {
	id: string; // this is actually an ARI , example - ari:cloud:confluence::site/690973c4-d02a-470f-b795-0af9b28f75c1
	baseUrl: string;
	siteName: string;
};

export type TSiteMetadata = {
	siteId: string;
	siteName: string;
	isNounsAvailable: boolean;
	isRovoEnabled: boolean;
	productEdition?: TProductEdition;
	productDataRegions?: TProductDataRegions;
	rovoFeatures?: TRovoFeatures;
};

/**
 * The tier of certain products
 * @key Product Key
 * @value Product Tier
 * @example `{ 'confluence': "PREMIUM" }`
 */
export type TProductEdition = Record<string, string>;

/**
 * Defines where a product's data is sourced from
 * @key Product Key
 * @value List of data regions for the product
 * @example `{ 'confluence': ["us-west-2"], 'compass': ["us-east-1"] }`
 */
export type TProductDataRegions = Record<string, string[]>;

/**
 * Defines rovo specific features
 * @example `{ 'scas': true }`
 */
export type TRovoFeatures = Record<string, boolean>;

export type TSiteMetadataV2 = {
	siteId: string;
	siteName: string;
	isNounsAvailable: boolean;
	isRovoEnabled: boolean;
	applicationMode: string;
};

export type T3pResource = {
	type: string;
	outboundAuthUrl: string;
	isUserOAuthed: boolean;
	providerId: string;
	workspaceName?: string;
};

export type TSearchConfigAPIResponse = {
	resources: TResource[];
	intercomHmac: string;
	siteMetadata: TSiteMetadata[];
};

export type TSearchConfigAPIResponseV2 = {
	resources: TResource[];
	intercomHmac: string;
	siteMetadata: TSiteMetadata[];
};

export type LoomUserAuthResponse = {
	status?: string;
};

export type TSearch3pConfigAPIResponse = T3pResource[];

// https://developer.atlassian.com/platform/cross-product-search/rest/api-group-other-operations/#api-configuration-v1-get
export const getSearchConfig = async ({
	absoluteUrl = '',
	hostName,
	cloudId,
	userId,
	onlyRefreshIfExpired,
	expiration,
}: TSearchConfigProps): Promise<TSearchConfigAPIResponse> => {
	const url = absoluteUrl || `${hostName || ''}${RELATIVE_URL}`;

	const options: RequestServiceOptions = {
		requestInit: {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
			},
		},
	};

	const key = generateHash(`1p|${userId}|${cloudId}`);
	const supplier = () =>
		utils
			.requestService<TSearchConfigAPIResponse>({ url }, options)
			.then((data: TSearchConfigAPIResponse) => {
				const resources = data.resources.filter((resource) =>
					cloudId
						? resource.id.includes(cloudId) ||
							SITELESS_PRODUCTS.find((product) => resource.id.includes(product)) // Allow access to products that are not associated with a site
						: true,
				);

				return {
					resources: resources,
					intercomHmac: data.intercomHmac || '',
					siteMetadata: data.siteMetadata || [],
				};
			});

	return refreshOnAccessDecoratorSearch({
		key: key.toString(),
		supplier,
		onlyRefreshIfExpired,
		expiration,
	});
};

// https://developer.atlassian.com/platform/cross-product-search/rest/api-group-other-operations/#api-api-v2-configuration-cloudid-get
export const getSearchConfigV2 = async ({
	absoluteUrl = '',
	hostName,
	cloudId,
	userId,
	onlyRefreshIfExpired,
	expiration,
	degradationCallback = () => {},
}: TSearchConfigProps): Promise<TSearchConfigAPIResponseV2> => {
	const url = absoluteUrl || `${hostName || ''}${RELATIVE_URL_V2}/${cloudId}`;

	const options: RequestServiceOptions = {
		requestInit: {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
			},
		},
	};

	const key = generateHash(`1p|${userId}|${cloudId}|v2`);

	const supplier: () => Promise<TSearchConfigAPIResponse> = () => {
		const searchConfigPromise = utils
			.requestService<TSearchConfigAPIResponse>({ url }, options)
			.then((data: TSearchConfigAPIResponse) => {
				return {
					resources: data.resources,
					intercomHmac: data.intercomHmac || '',
					siteMetadata: data.siteMetadata || [],
				};
			});

		if (fg('rovo_search_graceful_degradation')) {
			// Two cases here, due to the promiseCache from refreshOnAccessDecoratorSearch below
			// 1. Cache-miss: we of course want to handle degradation!
			// 2. Cache-hit: we actually still want to handle degradation, because if we can't refresh the cache due to a slow dependency, that is a signal we need in healthchecks
			// The alternate approach here would be to wrap the promiseCache in the DegradablePromise, but then we'd only get degradation pings on cache-miss.
			const degrader = new DegradablePromise<TSearchConfigAPIResponse>(
				searchConfigPromise,
				degradationCallback,
			);

			return degrader.promise();
		} else {
			return searchConfigPromise;
		}
	};

	return refreshOnAccessDecoratorSearch({
		key: key.toString(),
		supplier,
		onlyRefreshIfExpired,
		expiration,
	});
};

/**
 * Sample response:
 * [
      {
        "type": "ai-3p-connector:google-workspace",
        "location": "ari:third-party:google::site/DUMMY-0c418054-69d8-4a6c-bec6-8ef053070a36"
      }
    ]
 */

export const get3pSearchConfig = async ({
	absoluteUrl = '',
	cloudId,
	userId,
	onlyRefreshIfExpired,
	expiration,
}: TSearch3pConfigProps): Promise<TSearch3pConfigAPIResponse> => {
	const baseUrl = absoluteUrl || RELATIVE_3P_URL;
	const url = `${baseUrl}/${cloudId}`;

	const options: RequestServiceOptions = {
		requestInit: {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
			},
		},
	};
	const key = generate3pHash(userId || '', cloudId || '');

	const supplier = () => utils.requestService<TSearch3pConfigAPIResponse>({ url }, options);

	return refreshOnAccessDecorator3pSearch({
		key: key.toString(),
		supplier,
		returnExpired: false,
		onlyRefreshIfExpired,
		expiration,
	});
};

export const generate3pHash = (userId: string, cloudId: string) =>
	generateHash(`3p|${userId}|${cloudId}`);

/**
 * Sample response:
 *
      {
        "status": "mastered",
      }
 */

export const getUserLoomAuthStatus = async ({
	cloudId,
	userId,
}: LoomUserAuthProps): Promise<LoomUserAuthResponse> => {
	const url = RELATIVE_LOOM_AUTH_URL;
	const options: RequestServiceOptions = {
		requestInit: {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
			},
		},
	};
	const key = generateLoomAuthHash(userId || '', cloudId || '');

	const supplier = () => utils.requestService<LoomUserAuthResponse>({ url }, options);

	return refreshOnAccessDecoratorUserLoomAuth({
		key: key.toString(),
		supplier,
		returnExpired: false,
	});
};

export const generateLoomAuthHash = (userId: string, cloudId: string) =>
	generateHash(`loom|${userId}|${cloudId}`);
