import { ExperimentExperience } from '../../../schemas/dynamic-config';
import type { BackendExperiment } from '../../../types';
import { getLayerValues } from '../backend';

import type {
	DynamicExperiment,
	DynamicExperiments,
	EntityFilters,
	ProductDataRegions,
	ProductEdition,
	SearchExperimentLayer,
} from './types';

const WILDCARD = '*';

export const mapDynamicExperiments = ({
	dynamicExperiments,
	requestedEntities,
	productEdition,
	isHello,
	productDataRegions,
	experience,
}: {
	dynamicExperiments: DynamicExperiments;
	requestedEntities: EntityFilters;
	productEdition?: ProductEdition;
	isHello?: boolean;
	productDataRegions?: ProductDataRegions;
	experience?: ExperimentExperience;
}): BackendExperiment => {
	const experiments = dynamicExperiments
		.filter(isSameConfluenceTier(productEdition))
		.filter(containsExperience(experience))
		.filter(isHelloTenant(isHello))
		.filter(containsRequestedEntities(requestedEntities))
		.filter(containsRequestedSubEntities(requestedEntities))
		.filter(satisfiesProductDataRegions(productDataRegions))
		.map((dynamicExp) => {
			const { layerId, shadowId, abTestId, controlId } = getLayerValues(dynamicExp.statsigLayerId);
			return {
				definition: {
					layerId,
					shadowId,
					entity: dynamicExp.entity,
					subEntity: dynamicExp.subEntity,
					abTestId,
					controlId,
					statsigLayerId: dynamicExp.statsigLayerId,
				},
				layerType: dynamicExp.layerType,
			};
		});

	const groupedExperimentLayers = experiments.reduce<SearchExperimentLayer[]>(
		(experimentLayers, definitionWithLayerType) => {
			const existingLayer = experimentLayers.find(
				(layer) => layer.name === definitionWithLayerType.layerType,
			);
			const { abTestId, controlId, statsigLayerId, ...newDefinition } =
				definitionWithLayerType.definition;
			const analyticsDefinition = definitionWithLayerType.definition;

			if (!existingLayer) {
				experimentLayers.push({
					name: definitionWithLayerType.layerType,
					layerId: null, // TODO QS-6283: Remove this once scalable statsig layers are rolled out
					shadowId: null, // TODO QS-6283: Remove this once scalable statsig layers are rolled out
					definitions: [newDefinition],
					analyticsDefinitions: [analyticsDefinition],
				});
				return experimentLayers;
			}

			existingLayer.definitions?.push(newDefinition);
			existingLayer.analyticsDefinitions?.push(analyticsDefinition);

			return experimentLayers;
		},
		[],
	);

	return {
		experimentLayers: groupedExperimentLayers,
	};
};

const isSameConfluenceTier =
	(productEdition?: ProductEdition) => (dynamicExp: DynamicExperiment) => {
		// Opt for specificity. Does not filter out values that do not have the confluenceTier attribute
		if (!dynamicExp.confluenceTier || dynamicExp.confluenceTier.length === 0) {
			return true;
		}
		return dynamicExp.confluenceTier?.find(
			(experimentTier) => productEdition?.['confluence'] === experimentTier,
		);
	};

const isHelloTenant = (isHello?: boolean) => (dynamicExp: DynamicExperiment) => {
	if (dynamicExp.hello === undefined || dynamicExp.hello === null) {
		return true;
	}

	return dynamicExp.hello === isHello;
};

const containsRequestedEntities =
	(entityFilter: EntityFilters) => (dynamicExp: DynamicExperiment) => {
		if (!dynamicExp.entity || dynamicExp.entity === WILDCARD) {
			return true;
		}

		const isFilteredEntity = entityFilter.find(
			(filteredEntity) => dynamicExp.entity === filteredEntity.entity,
		);
		return isFilteredEntity ?? false;
	};

const containsRequestedSubEntities =
	(entityFilter: EntityFilters) => (dynamicExp: DynamicExperiment) => {
		if (!dynamicExp.subEntity || dynamicExp.subEntity === WILDCARD) {
			return true;
		}

		const isFilteredSubEntity = entityFilter.find(
			(filteredEntity) => dynamicExp.subEntity === filteredEntity.subEntity,
		);
		return isFilteredSubEntity ?? false;
	};

/**
 * Determines whether the provided data regions (dataRegions) are defined in
 * the dynamic configuration (dynamicExp). Every data region for a given product
 * in the dynamic configuration must be specified  in order for the given
 * experiment layer to be eligible.
 *
 * @param dataRegions Represents where the data comes from comes from for
 * each product.
 * ```
 * { 'confluence': ["us-west-2"], 'compass': ["us-east-1"] }`
 * ```
 *
 * @returns a boolean that indicates whether or not the provided data regions
 * (dataRegions) are specified in the dynamic configuration (dynamicExp).
 */
const satisfiesProductDataRegions =
	(dataRegions?: ProductDataRegions) => (dynamicExp: DynamicExperiment) => {
		if (!dynamicExp.productDataRegions) {
			return true;
		}

		return Object.keys(dynamicExp.productDataRegions)
			.map((product) => {
				const productDataRegions = dynamicExp.productDataRegions?.[product];
				const isProductEligible = dataRegions?.[product]?.every((dataRegion) => {
					return productDataRegions?.includes(dataRegion);
				});
				return !!isProductEligible;
			})
			.reduce((prev, curr) => prev && curr);
	};

const containsExperience =
	(experience?: ExperimentExperience) => (dynamicExp: DynamicExperiment) => {
		if (!dynamicExp.experiences || dynamicExp.experiences.length === 0) {
			return true;
		}

		return dynamicExp.experiences?.find(
			(dynamicExpExperience) => experience === dynamicExpExperience,
		);
	};
