import FeatureGates from '@atlaskit/feature-gate-js-client';

import type { MultiLayerBackendExperiment } from '../../../types';
import {
	DEFAULT_STATSIG_PARALLEL_EXPERIMENTS_CONFIG,
	STATSIG_SEARCH_BACKEND_FEATURE_GATE_L1_STANDARD_VS_PREMIUM,
	STATSIG_SEARCH_BACKEND_FEATURE_GATE_L2_STANDARD_VS_PREMIUM,
	STATSIG_SEARCH_BACKEND_FEATURE_GATE_L3_ENABLED,
	STATSIG_SEARCH_BACKEND_FEATURE_GATE_QUERY_EXPANSION_ENABLED,
	STATSIG_SEARCH_BACKEND_L1_LAYER_PREMIUM,
	STATSIG_SEARCH_BACKEND_L1_LAYER_STANDARD,
	STATSIG_SEARCH_BACKEND_L2_BLENDING_LAYER,
	STATSIG_SEARCH_BACKEND_L2_LAYER_PREMIUM,
	STATSIG_SEARCH_BACKEND_L2_LAYER_STANDARD,
	STATSIG_SEARCH_BACKEND_L3_LAYER,
	STATSIG_SEARCH_BACKEND_QUERY_EXPANSION_LAYER,
} from '../../constants';

export const getBackendExperimentConfig = (
	onErrorFn?: (error: any) => any,
	shouldNotGetBackendExperiment: boolean = false,
) => {
	const isRequestDrivenExperimentationEnabled = FeatureGates.checkGate(
		'request_driven_experimentation_enabled',
	);
	if (!isRequestDrivenExperimentationEnabled || shouldNotGetBackendExperiment) {
		return;
	}

	return getParallelBackendExperimentsConfig(onErrorFn);
};

export const getLayerValues = (layerName: string) => {
	const layer = FeatureGates.getLayer(layerName);

	const layerNameWithoutSuffix = layerName.replace('_standard', '').replace('_premium', '');
	return {
		name: layerNameWithoutSuffix,
		layerId: layer.get<string | null>('layerId', 'default'),
		shadowId: layer.get<string | null>('shadowId', null),

		// The below values might not be sent to the backend but we can still track them in the frontend
		controlId: layer.get<string | null>('controlId', null),
		abTestId: layer.get<string | null>('abTestId', 'no_ab_test_id'),
		...(FeatureGates.checkGate('search-experiment-overrides-backend') && {
			testId: layer.get<string | null>('testId', null),
		}),
	};
};

const getStandardOrPremiumLayerValues = (
	featureGateName: string,
	standardLayerName: string,
	premiumLayerName: string,
) => {
	const isPremium = FeatureGates.checkGate(featureGateName);
	if (isPremium) {
		return getLayerValues(premiumLayerName);
	}
	return getLayerValues(standardLayerName);
};

const getParallelBackendExperimentsConfig = (
	onErrorFn?: (error: any) => any,
): MultiLayerBackendExperiment | undefined => {
	const isRequestDrivenExperimentationEnabled = FeatureGates.checkGate(
		'request_driven_experimentation_enabled',
	);

	if (!isRequestDrivenExperimentationEnabled) {
		return;
	}

	try {
		const allLayers = [];
		const layer1 = getStandardOrPremiumLayerValues(
			STATSIG_SEARCH_BACKEND_FEATURE_GATE_L1_STANDARD_VS_PREMIUM,
			STATSIG_SEARCH_BACKEND_L1_LAYER_STANDARD,
			STATSIG_SEARCH_BACKEND_L1_LAYER_PREMIUM,
		);
		allLayers.push(layer1);

		const layer2 = getStandardOrPremiumLayerValues(
			STATSIG_SEARCH_BACKEND_FEATURE_GATE_L2_STANDARD_VS_PREMIUM,
			STATSIG_SEARCH_BACKEND_L2_LAYER_STANDARD,
			STATSIG_SEARCH_BACKEND_L2_LAYER_PREMIUM,
		);
		allLayers.push(layer2);
		const layer2Blending = getLayerValues(STATSIG_SEARCH_BACKEND_L2_BLENDING_LAYER);
		allLayers.push(layer2Blending);

		const isLayer3Enabled = FeatureGates.checkGate(STATSIG_SEARCH_BACKEND_FEATURE_GATE_L3_ENABLED);
		if (isLayer3Enabled) {
			const layer3 = getLayerValues(STATSIG_SEARCH_BACKEND_L3_LAYER);
			allLayers.push(layer3);
		}

		const isQueryExpansionLayerEnabled = FeatureGates.checkGate(
			STATSIG_SEARCH_BACKEND_FEATURE_GATE_QUERY_EXPANSION_ENABLED,
		);
		if (isQueryExpansionLayerEnabled) {
			const layerQueryExpansion = getLayerValues(STATSIG_SEARCH_BACKEND_QUERY_EXPANSION_LAYER);
			allLayers.push(layerQueryExpansion);
		}

		const concatenatedExperimentId = allLayers.map((l) => l?.layerId).join('_');
		const concatenatedShadowExperimentId = allLayers.map((l) => l?.shadowId).join('_');
		return {
			experimentId: concatenatedExperimentId,
			shadowExperimentId: concatenatedShadowExperimentId,
			experimentLayers: allLayers,
		};
	} catch (error) {
		onErrorFn?.(error);
	}

	return DEFAULT_STATSIG_PARALLEL_EXPERIMENTS_CONFIG;
};
