import { Trigger } from './events';
import crypto from 'crypto';

export const sha256Hash = (str: string): string =>
	crypto.createHash('sha256').update(str).digest('hex');

export const getTrigger = (
	e: React.MouseEvent<any, MouseEvent> | MouseEvent | KeyboardEvent,
): Trigger => {
	if (e instanceof KeyboardEvent) {
		return Trigger.RETURN;
	}
	return e.screenX === 0 ? Trigger.RETURN : Trigger.CLICK;
};

export const isNewTab = (e: React.MouseEvent<any, MouseEvent> | MouseEvent | KeyboardEvent) => {
	return !!(e && (e.metaKey || e.ctrlKey || e.shiftKey));
};

export const getWordCount = (str: string) => {
	return str.length > 0 ? str.split(/\s+/).length : 0;
};
